//*----------  COMPONENTS  ----------*/
import DashBoard      from '@/components/DashBoard/DashBoard.vue'
import DataGrid       from '@/components/DataGrid/DataGridSimple/DataGridSimple.vue'
    // ? TabsItems
import TabCategoria   from './componentes/TabsItems/tabCategoria.vue'
import service        from '@/services/soporte'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'

// import Excel          from '@/libs/jsExcel';
// import DiffCell       from './componentes/complementos/ColorCell.vue';
import { mapActions } from 'vuex';

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Categoria",
    
    components : {
        DashBoard,
        DataGrid,
        TabCategoria,
        DialogForm
    },
    props : {
        dataConcepto: Object
    },
    data : () => ({
        new    : true,
        lazy   : false,
        configGrid : {
            summary : true,
            paging : {
                properties : {
                    "page-size" : 25
                }
            },
            export : {
                export          : false
            },
        },
        mainTab   : null,
        title     : "Nuevo",
        valid     : false,
        users : [],
        user : null,
        actions : [],
        disabled : false,
        errores : {
            categoria : false,
            descripcion : false,
        },
        rules : {
            categoria : [
                v => !!v || 'Ingrese nombre de la categoría',
            ],
            descripcion : [
                v => !!v || 'Ingrese descripción',
            ]
        },
        // ********************************
        idCT        : -1,
        categoria    : null,
        descripcion : null,
        fechaU      : null,
        fullWidth : true,
        dataSource : [],
        
        dtEdit     : null,
        // ?? mostart datos eliminados
        dataFunction : [{
            format : ( data ) => {
                let res = data.val;
                return res;
            },
            class : ( data ) => {
                let res = data.data.data.activo == 1 ? "" : "delete"
                return res;
            }
        }],
        oldObject : {},
        id_baseCtEstatus : 1
    }),
    computed : {
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions("categoria",  ["setDataSource"] ),
        // ??? Eventos click de los botones de menu, se pueden reconocer por el idBtn o por siu diRlCA
        loadEvent( {idRlCA, idBtn} ) {
            switch( idBtn ) {
                case "btnNuevo": 
                    this.clear();
                    this.new    = true;
                    this.setDialog({
                        key    : "dlgCategoria",
                        status : true
                    });
                break;
            }
        },
        /* 
        *   Las acciones que se tienen en la tabla principal, se recibe un objeto
        *   event       =>  Datos del renglon donde se puede hacer la actualizacion de los datos que se tienen en ella {event, component, element, row, column} la propiedad row contienen los valores que se pueden cambiar en row.data
        *   dataAction  =>  Objeto que contiene los datos del permiso asignado{ idRlCA, accion, urlIcon, idBtn}, para reconocer el evento se puede reconocer por el idRlCA o por elidBtn
        ??? Estas propiedades se construyen en DistribucionGen.js
        */
        eventActionTable({ event, dataAction }) {
            switch( dataAction.idBtn ) {
                case "btnEdicion"   : this.edit( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
                case "btnEliminar"  : this.eliminar( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
                case "btnActive"    : this.activar( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
            }
        },
        clear() {
            this.idCT        = -1;
            this.user        = null;
            this.categoria    = null;
            this.descripcion = null;
            this.fechaU      = null;
            this.disabled    = false;
            this.rules = {
                users : [],
                categoria : [],
                descripcion : []
            }
            this.oldObject   = {};
        },
        // ?? Funciones para la tabla principal
        edit( data ) {
            let dataRow      = data.row.data;
            if(dataRow.activo != 1) {
                this.$Msg.error("Solo se puede editar un categoria activo.")
                return;
            }
            this.idCT        = dataRow.id;
            this.user        = dataRow.id_adminCtUser;
            this.categoria   = dataRow.categoria;
            this.descripcion = dataRow.descripcion;
            this.fechaU      = dataRow.fechaU;
            this.disabled    = true;
            this.setDialog({
                key    : "dlgCategoria",
                status : true
            });
            this.oldObject = this.buildObjec();
            this.new    = false;
        },
        eliminar( data ){
            let dataRow = data.row.data;
            let arrInfo         = [];
            let ctcategoria  = [];
            this.$Msg.confirma(`¿Desea eliminar la categoría ${dataRow.categoria}?`).then( result => {
                if( result.value ){
                    ctcategoria.push({
                        id_soporteCtCategoria : dataRow.id,
                        activo               : -1,
                        updated_at           : dataRow.fechaU
                    });
                    arrInfo.push( ( this.$JsTable.getFormat( "soporteCtCategoria", "Update", ctcategoria, ["id_soporteCtCategoria"] ) ) );
                    service.SopU_eliminarCategoria( arrInfo ).then( result => {
                        if( this.$Msg.fn( { data: result, message: "Registro eliminado correctamente" } ) )
                            this.cargarData( );
                    })
                }
            });
        },
        activar( data ){
            let dataRow = data.row.data;
            let arrInfo         = [];
            let ctcategoria  = [];
            this.$Msg.confirma(`¿Desea ${dataRow.activo == 1 ? "desactivar" : "activar"} la categoría ${dataRow.categoria}?`).then( result => {
                if( result.value ){
                    ctcategoria.push({
                        id_soporteCtCategoria : dataRow.id,
                        activo               : dataRow.activo == 1 ? 0 : 1,
                        updated_at           : dataRow.fechaU
                    });
                    arrInfo.push( ( this.$JsTable.getFormat( "soporteCtCategoria", "Update", ctcategoria, ["id_soporteCtCategoria"] ) ) );
                    service.SopU_activarCategoria( arrInfo ).then( result => {
                        if( this.$Msg.fn( { data: result, message: `Registro ${dataRow.activo == 1 ? "desactivado" : "activado"} correctamente` } ) )
                            this.cargarData( );
                    })
                }
            });
        },
        // ?? Funcion para la tabla de detallado
        eventClose() {
            this.reset();
            this.setDialog({
                key    : "dlgCategoria",
                status : false
            });
        },
        eventGen() {
            try {
                this.rules = {
                    categoria : [
                        v => !!v || 'Ingrese nombre del categoria',
                    ],
                    descripcion : [
                        v => !!v || 'Ingrese descripción',
                    ]
                }
                if( this.validate() ) {
                    if( this.new ) {
                        let categoria = this.buildObjec();
                        let arrInfo = [];
                        arrInfo.push(this.$JsTable.getFormat("soporteCtCategoria",  "Insert", [ categoria ],  ["id_soporteCtCategoria"]));
                        service.SopC_categoria( arrInfo ).then( result => {
                            if( this.$Msg.fn( {data : result, message : "categoria creado correctamente" } )) {
                                this.eventClose();
                                this.cargarData();
                            }
                        })
                    } else {
                        let categoria = this.buildObjec();
                        let arrInfo = [];
                        let ctCategoria = this.$fn.getDiffObj( [this.oldObject],[categoria],["id_soporteCtCategoria"])
                        if( ctCategoria == 0 )
                            throw "No se realizó ningún cambio"
                        ctCategoria[0].updated_at = this.fechaU;
                        arrInfo.push(this.$JsTable.getFormat("soporteCtCategoria",  "Update", ctCategoria,  ["id_soporteCtCategoria"]));
                        service.SopU_categoria( arrInfo ).then( result => {
                            if( this.$Msg.fn( {data : result, message : "categoria actualizado correctamente" } )) {
                                this.eventClose();
                                this.cargarData();
                            }
                        })
                    }
                }
            } catch (error) {
                this.$Msg.error( error )
            }
        },
        buildObjec() {
            let obj = {
                id_soporteCtCategoria : this.idCT,
                categoria             : this.categoria,
                descripcion          : this.descripcion,
                activo               : 1
            }
            return obj;
        },
        validate() {
            return this.$refs.form.validate()
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        cargarData(  ){
            service.SopR_dataSourceCategorias().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
    }
}
